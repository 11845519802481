export function getDate(pos = "", num = 0) {
  let myDate = new Date();

  switch (pos) {
    case "month":
      myDate.setMonth(myDate.getMonth() + num);
      break;
    case "date":
      myDate.setDate(myDate.getDate() + num);
      break;
    case "year":
      myDate.setFullYear(myDate.getFullYear() + num);
      break;
  }

  let year = myDate.getFullYear();
  let month = ('0' + (myDate.getMonth() + 1)).slice(-2); // Meses en JS van de 0 a 11
  let date = ('0' + myDate.getDate()).slice(-2);

  return `${year}-${month}-${date}`;
}

export function formatDate(date, format, splitter = '-'){
  if(date){
    let res = date.split(splitter);
    let year, month, day;
    const zero = "0";
    var length0 = res[0].toString().length;
    var length1 = res[1].toString().length;
    var length2 = res[2].toString().length;
    if(length0 == 1){
      res[0] = zero+res[0];
    }
    if(length1 == 1){
      res[1] = zero+res[1];
    }
    if(length2 == 1){
      res[2] = zero+res[2];
    }
    if(res[0].length > 2){
      year = res[0].substr(0,4);
      day = res[2].substr(0,2);
    }else{
      year = res[2].substr(0,4);
      day = res[0].substr(0,2);
    }
    month = res[1].substr(0,2);
    switch(format){
      case "dd/mm/yyyy":
        return day + '/' + month + '/' + year;
      case "yyyy/mm/dd":
        return year + '/' + month + '/' + day;
      case "dd-mm-yyyy":
        return day + '-' + month + '-' + year;
      case "yyyy-mm-dd":
        return year + '-' + month + '-' + day;
      case "mm/dd/yyyy":
        return month + '/' + day + '/' + year;
      default:
        return '';
    }
  }
}

function addDays(date, days) {
  var newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
}
function subtractDays(date, days) {
  var newDate = new Date(date);
  newDate.setDate(newDate.getDate() - days);
  return newDate;
}

function remaingDays(date) {
  var myDate = new Date(date);
  var now = new Date();
  var remaing = myDate.getTime() - now.getTime();
  var remaingDays = Math.ceil(remaing / (1000 * 60 * 60 * 24));
  return remaingDays;
}

export function validEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function returnError(err){
  switch(err){
    case 400:
      return "Error en la aplicación. Consulte a soporte técnico.";
    case 401:
      return "No tienes permiso para llevar a cabo esta operación.";
    case 500:
      return "Error en el servidor. Consulte a soporte técnico.";
  }
}